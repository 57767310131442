import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {DropdownButton ,Dropdown  } from 'react-bootstrap'
import "./navbar.css"
import DummyAvatar from "../../assets/imgs/avatar-1.jpg"
import {NavLink} from 'react-router-dom'

function Navbar({checkToggle, setCheckToggle}){

    return (
    <div className="App sb-nav-fixed">
        <nav className="sb-topnav navbar navbar-expand">
            <NavLink to="/" className="nav-link ps-3" style={{color:"white"}} >SportCC</NavLink>
             <div >
            <button  className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" onClick={() =>setCheckToggle(!checkToggle)} id="sidebarToggle" href="#!"><FontAwesomeIcon icon={faBars} className="text-white" /></button></div>
            <div className="ms-auto me-0 me-md-3 my-2 my-md-0"> 
                <DropdownButton id="dropdown-item-button" title={<img src={DummyAvatar} className="admin-avatar img-fluid " alt="admin" />}>
                    <Dropdown.Item  as="button">Settings</Dropdown.Item>
                    <Dropdown.Item as="button">Activity</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as="button">Logout</Dropdown.Item>
                </DropdownButton>
            </div>
        </nav>
    </div>
    );
}


export default Navbar;